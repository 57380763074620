/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Fragment, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

import { toast } from 'react-toastify'
import {
  getCampaign,
  getCommittee,
  getCommittees,
} from '../../../../../services/campaigns'

import Loader from '../../../../../images/icons/loadingbutton.svg'

import { Form, FormControl } from './styles'

const Component = ({ setLogged, setSpecialCampaign, setComiteId }) => {
  const [comite, setComite] = useState(0)
  const [comites, setComites] = useState([])
  const [isLoading, setLoading] = useState(false)

  const fetchCommittees = async () => {
    try {
      const response = await getCommittees()

      const committees = response.data.map(c => {
        return { name: c.name, id: c.id }
      })

      setComites(committees)
    } catch (error) {
      toast.warn(error)
    }
  }

  useEffect(() => {
    fetchCommittees()
  }, [])

  const loadCampaing = async id => {
    setLoading(true)

    try {
      const response = await getCampaign(id)

      const { campaignName, description, images } = response.data

      const specialCampaign = {
        id,
        name: campaignName,
        description,
        image: images[0],
      }

      setSpecialCampaign(specialCampaign)

      sessionStorage.setItem('@denario_app/christmas_campaign/logged', true)
      sessionStorage.setItem(
        '@denario_app/christmas_campaign/special_campaign',
        JSON.stringify(specialCampaign)
      )
    } catch (err) {
      toast.error(err)
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()

    try {
      const response = await getCommittee({ id: comite, accessCode: null })

      const { results } = response.data
      const { id } = results[0]
      loadCampaing(id)
    } catch (error) {
      const noCampaing = error.response.status === 404

      if (noCampaing) {
        const emptyCampaignObject = {
          id: 0,
          name: 0,
          description: null,
          image: null,
        }

        setSpecialCampaign(emptyCampaignObject)
        sessionStorage.setItem(
          '@denario_app/christmas_campaign/special_campaign',
          JSON.stringify(emptyCampaignObject)
        )

        sessionStorage.setItem('@denario_app/christmas_campaign/logged', true)
      }
    }
  }

  return (
    <AnimatePresence>
      <Form
        key="login"
        onSubmit={handleSubmit}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 200 }}
        transition={{ delay: 1 }}
      >
        <FormControl>
          <label htmlFor="comite">Cidade</label>
          <select
            name="comite"
            id="comites"
            defaultValue="default"
            onBlur={event => {
              setComite(event.target.value)
              setComiteId(event.target.value)
            }}
          >
            <option value="default" disabled hidden>
              Selecione
            </option>
            <Fragment>
              {comites.map(comite => (
                <option key={comite.id} value={comite.id}>
                  {comite.name}
                </option>
              ))}
            </Fragment>
          </select>
        </FormControl>

        <motion.button
          type="submit"
          whileTap={{ scale: 0.97 }}
          whileHover={{ scale: 1.03 }}
        >
          {isLoading ? <Loader /> : 'Ver campanhas'}
        </motion.button>
      </Form>
    </AnimatePresence>
  )
}

Component.propTypes = {
  setLogged: PropTypes.func.isRequired,
  setComiteId: PropTypes.func.isRequired,
  setSpecialCampaign: PropTypes.func.isRequired,
}

export default Component
