/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useState, useEffect } from 'react'

import { Link } from 'gatsby'
import { AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

import Purina from '../../../../../images/campanha-nestle/banner/natal-feliz-purina.jpg'
import letter from '../../../../../images/campanha-nestle/letter.png'

import { Container, Card, Image, Title } from './styles'

const Component = ({ specialCampaign, booksCampaign }) => {
  const [hasSpecialCampaign, setHasSpecialCampaign] = useState(false)
  const [isLetterShuffleCampaign, setLetterShuffleCampaign] = useState(false)

  const [isSaoPauloCitySelected, setIsSaoPauloCitySelected] = useState(false)

  const comiteId = sessionStorage.getItem(
    '@denario_app/christmas_campaign/comite_id'
  )

  useEffect(() => {
    setIsSaoPauloCitySelected(parseInt(comiteId, 10) === 37)
  }, [])

  useEffect(() => {
    if (specialCampaign.id !== 0) {
      setHasSpecialCampaign(true)
    }

    if (
      specialCampaign.name === 'Cartinha de Natal' ||
      specialCampaign.name === 'Compartilhe Alegria'
    ) {
      setLetterShuffleCampaign(true)
    }

    sessionStorage.setItem(
      '@denario_app/christmas_campaign',
      JSON.stringify({ christmas_campaign: true })
    )

    sessionStorage.setItem('@denario_app/christmas_campaign/fixed-value', false)
  }, [])

  return (
    <AnimatePresence>
      <Title
        key="home-title"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 200 }}
        transition={{ delay: 0.3 }}
      >
        Alimentar um natal mais feliz faz bem
      </Title>

      <Container
        key="campaign-list"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 200 }}
        transition={{ delay: 0.3 }}
      >
        {hasSpecialCampaign && (
          <Card whileHover={{ scale: 1.03 }}>
            <Image src={specialCampaign.image} />

            <h2>{specialCampaign.name}</h2>

            <div>
              <p>{specialCampaign.description}</p>
            </div>
            <Link
              to={
                isLetterShuffleCampaign
                  ? '/pt/campanha-natal-nestle/compartilhar-alegria/sortear-carta'
                  : `/pt/campanhas/doar/${specialCampaign.id}/`
              }
              state={{ campaignId: specialCampaign.id, comiteId }}
            >
              Quero Participar
            </Link>

            <img src={letter} alt="letter" style={{ display: 'none' }} />
          </Card>
        )}

        <Card whileHover={{ scale: 1.03 }}>
          <Image src={booksCampaign.image} />

          <h2>{booksCampaign.name}</h2>

          <div>
            <p>{booksCampaign.description}</p>
          </div>
          <Link
            to={`/pt/campanhas/doar/${booksCampaign.id}/`}
            state={{ christmas_campaign: true }}
          >
            Quero Participar
          </Link>
        </Card>

        {isSaoPauloCitySelected && (
          <Card whileHover={{ scale: 1.03 }}>
            <Image src={Purina} />

            <h2>Todo mundo merece um natal feliz | Purina</h2>

            <div>
              <p>
                Você dá uma nova moradia para um cãozinho necessitado. E o
                cãozinho leva alegria para a sua casa. Afinal, todo mundo merece
                um Natal mais feliz.
              </p>
            </div>
            <button type="button" disabled>
              Em Breve
            </button>
          </Card>
        )}
      </Container>
    </AnimatePresence>
  )
}

export default Component
