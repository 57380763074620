/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useState, useEffect } from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../../Layout/christmas'

import { getCampaign } from '../../../../services/campaigns'

import ListaCampanhas from './ListaCampanhas'
import Login from './Login'

import {
  Container,
  Header,
  HeaderImage,
  Footer,
  FooterLogo,
  FooterPage,
  FooterImages,
  Main,
} from './styles'

import BackBtnComponent from '../BackBtn';

const Component = () => {
  const data = useStaticQuery(graphql`
    query {
      voluntariar: file(
        relativePath: { eq: "campanha-nestle/logo_voluntariar.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 478) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nestle: file(relativePath: { eq: "campanha-nestle/logo-nestle.png" }) {
        childImageSharp {
          fluid(maxWidth: 159) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nestlePage: file(
        relativePath: { eq: "campanha-nestle/logo-nestle-page.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 264) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const [isLogged, setLogged] = useState(false)
  // const [comiteId, setComiteId] = useState(0)

  const [specialCampaign, setSpecialCampaign] = useState({})
  const [booksCampaign, setBookCampaign] = useState({})

  useEffect(() => {
    const logged = sessionStorage.getItem(
      '@denario_app/christmas_campaign/logged'
    )

    setLogged(Boolean(logged))
  })

  useEffect(() => {
    const specialCampaignStorage = JSON.parse(
      sessionStorage.getItem('@denario_app/christmas_campaign/special_campaign')
    )

    setSpecialCampaign(specialCampaignStorage)
  }, [])

  async function loadBookCampaing() {
    const bookCampaignId = 40

    try {
      const response = await getCampaign(bookCampaignId)

      const { campaignName, description, images } = response.data

      setBookCampaign({
        id: bookCampaignId,
        name: campaignName,
        description,
        image: images[0],
      })
    } catch (err) {
      console.log(err)
    }
  }

  function setComiteId(id) {
    sessionStorage.setItem('@denario_app/christmas_campaign/comite_id', id)
  }

  useEffect(() => {
    loadBookCampaing()
  }, [])

  return (
    <Layout>
      <Container>
        <Header>
          <HeaderImage
            loading="eager"
            fluid={data.voluntariar.childImageSharp.fluid}
          />
        </Header>

        {isLogged && (
          <BackBtnComponent/>
        )}

        <Main>
          {!isLogged && (
            <Login
              setLogged={setLogged}
              setComiteId={setComiteId}
              setSpecialCampaign={setSpecialCampaign}
            />
          )}
          {isLogged && (
            <ListaCampanhas
              specialCampaign={specialCampaign}
              booksCampaign={booksCampaign}
            />
          )}
        </Main>

        <Footer>
          <FooterImages>
            <FooterLogo
              loading="eager"
              fluid={data.nestle.childImageSharp.fluid}
            />

            <FooterPage
              loading="eager"
              fluid={data.nestlePage.childImageSharp.fluid}
            />
          </FooterImages>
        </Footer>
      </Container>
    </Layout>
  )
}

export default Component
