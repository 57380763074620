import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { motion } from 'framer-motion'

export const Form = styled(motion.form)`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 10px #7c7c7c80;

  width: 360px;
  max-width: 100%;

  margin: auto;
  padding: 50px 40px;

  display: flex;
  flex-direction: column;

  font-family: 'MontSerrat', sans-serif;

  button {
    border-radius: 10px;

    cursor: pointer;

    background: #df0c18;
    color: #ffffff;

    margin: 10px auto 0;
    height: 40px;
    width: 180px;

    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 100%;
    }
  }
`

export const FormControl = styled.div`
  margin-bottom: 20px;
  text-align: left;

  width: 100%;

  label {
    display: block;
    margin-bottom: 10px;
  }

  input,
  select {
    background: #fff;
    border: 1px solid #999;
    border-radius: 4px;
    font-family: 'MontSerrat', sans-serif;
    padding: 10px 10px;
    width: 100%;
  }
`

export const FormControlCheckbox = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;

    display: flex;
    align-items: center;

    label {
      color: ${theme.colors.jet};
      font-size: 14px;

      display: inline-block;
      margin-right: 5px;
    }
  `}
`
