import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    font-family: 'MontSerrat', sans-serif;

    ${theme.queries.small} {
      max-width: 90vw;
      flex-direction: column;
    }

    ${theme.queries.large} {
      justify-content: center;
    }
  `}
`

export const Card = styled(motion.div)`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 10px #7c7c7c80;
  width: 100%;

  max-width: 100%;

  display: block;
  position: relative;

  padding: 20px 20px 100px;
  margin: 10px;

  h2 {
    margin: 15px 0 30px;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
  }

  p {
    text-align: center;
    line-height: 24px;
    font-size: 14px;
  }

  a,
  button {
    border: 2px solid #df0c18;
    border-radius: 10px;

    cursor: pointer;

    margin: auto auto 0;
    height: 40px;
    width: 180px;

    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 200ms ease-in-out;

    position: absolute;
    bottom: 30px;
    right: 50%;
    transform: translate(50%);

    &:hover {
      background: #df0c18;
      color: #ffffff;
    }

    &[disabled] {
      color: lightgray;

      &:hover {
        background: inherit;
        color: lightgray;
        cursor: not-allowed;
      }
    }
  }
`

export const Image = styled.div`
  ${({ src }) => css`
    background-image: url(${src});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 180px;
    width: 100%;
  `}
`

export const Title = styled(motion.h2)`
  ${({ theme }) => css`
    color: #ffffff;
    font-family: 'Bradley', Courier New;
    font-size: 28px;
    text-align: center;
    padding: 30px 0 50px;
    line-height: 36px;

    ${theme.queries.large} {
      padding: 50px 0 80px;
      font-size: 38px;
    }
  `}
`
