import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Img from 'gatsby-image'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Above;
  min-width: 320px;
  overflow-x: hidden;
  position: relative;
  padding: 0 20px;
`

export const Header = styled.header`
  display: block;
  width: 100%;
`

export const HeaderImage = styled(Img)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  height: auto;
  display: block;

  img {
    display: block;
    margin-left: 6px;
  }
`

export const Main = styled.main`
  ${({ theme }) => css`
    margin: auto;
    width: 100%;
    max-width: 1120px;

    ${theme.queries.small} {
      padding: 50px 0;
    }
  `}
`

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: block;
    position: relative;
    padding: 0;
    width: 100%;
  `}
`

export const FooterLogo = styled(Img)`
  display: inline-block;
  width: 156px;
  height: auto;
  margin: 50px -30px 50px 0px;
`

export const FooterPage = styled(Img)`
  width: 220px;
  height: auto;
  display: inline-block;
  transform: translateY(3px);
`

export const FooterImages = styled.div`
  display: block;
  margin-left: auto;
  max-width: 346px;
  margin-right: -20px;
`